@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~@ng-select/ng-select/themes/default.theme.css';

/*general*/
body {
  font-size: 15px;
}
.bold {
  font-weight: 700;
}

.tabs button {
  border: none;
  height: 39px;
  color: #639;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  margin-top: 10px;
  background: #efefef;
  border-right: solid 1px white;
  border-left: solid 1px white;
}

.disabled-tab {
  color: #979797 !important;
  font-weight: 500 !important;
}

.enabled-tab {
  /* background: #b9b9b9 !important; */
  border-bottom: solid 4px #ac91c1 !important;
}
.active-tab {
  border: solid 2px #6633996b !important;
  border-bottom: solid 4px #639 !important;
}

.tabs button:disabled {
  color: gray;
}

button {
  background-color: #41b1e5;
  border: none;
  color: white;
  font-weight: 700;
  padding: 0 11px;
  outline: none;
  font-size: 15px;
}

input[type='text'],
input[type='date'],
input[type='year'],
input[type='month'],
input[type='number'],
input[type='tel'],
input[type='time'] {
  background: none;
  border: solid 1px gainsboro;
  padding: 0px 5px;
  margin-bottom: 10px;
}

input[type='text'],
input[type='date'],
input[type='year'],
input[type='month'],
input[type='time'],
input[type='number'],
input[type='tel'],
button {
  height: 40px;
}
input[type='text']:disabled,
input[type='month'],
input[type='date'],
input[type='year']:disabled,
input[type='number'],
input[type='tel']:disabled,
input[type='time']:disabled {
  background: #f2f0f1;
}

.title-popup {
  color: #572879;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.chooes-btn {
  color: #639;
  font-size: 13px;
  background: #d6d4d4;
  height: inherit;
  padding: 6px 10px;
  margin-bottom: 10px;
}
.chooes-btn-selected {
  color: white;
  background: #639;
}

/* css for modal: */
.modal-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 96%;
  margin: 0 auto;
  margin-top: 9px;
  padding: 15px;
  text-align: center;
  border-radius: 0px;
  /* height: fit-content; */
}

/* css for ng select: */
ng-select > div > div.single > div.toggle {
  display: none;
}
ng-select > div > div.single {
  height: 27px !important;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
ng-select > div > div.single > div.value,
ng-select > div > div.single > div.placeholder {
  line-height: 27px !important;
}
#typesfor-rent ul.itemlist li:first-child {
  margin-bottom: 0px !important;
}

/*date picker small format css */
div.appDatePicker_smallFormat > ng-select > div > div.single > div.value,
div.appDatePicker_smallFormat > ng-select > div > div.single > div.placeholder {
  background-color: #ffffff !important;
  padding-right: 5px;
  color: black;
}

div.appDatePicker_smallFormat > ng-select > div > div.single {
  height: 25px !important;
}
div.appDatePicker_smallFormat > ng-select > div > div.single > div.value,
ng-select > div > div.single > div.placeholder {
  line-height: 25px !important;
}

div.appDatePicker_smallFormat > ng-select > div > div.single {
  font-size: 12px;
}

div.appDatePicker_smallFormat > input {
  font-size: 10px !important;
  width: 80% !important;
  margin-top: 4px;
}

/*date picker normal format css */
div.appDatePicker_normalFormat > ng-select > div > div.single > div.value,
div.appDatePicker_normalFormat
  > ng-select
  > div
  > div.single
  > div.placeholder {
}

/* palces auto-complete dropdown */
.pac-container {
  top: unset !important;
  bottom: 80px !important;
  position: fixed !important;
}

.has-error {
  border: 1px solid red !important;
}

/* ng-autocomplete */
.ng-autocomplete-input {
  width: 100%;
}

/* ng2-pdf-viewer */

.ng2-pdf-viewer-container[_ngcontent-c6] {
  overflow: scroll;
}

/* angular material */
mat-checkbox input {
  position: absolute !important;
}
